import { FieldValue, Timestamp } from '@firebase/firestore-types';

import { Agent } from './agent';
import { AiScores } from './conversation';
import { JobDisposition } from './job';

export interface Event {
  agent: Agent;
  cssClass: EventCssClass;
  date: Timestamp | FieldValue;
  id: string;
  state: EventState;
  messageType: EventType;
  text: string;
  errmsg?: string;
  translationParams?: object;
  account_id: string;
  disposition?: JobDisposition;
  source?: EventSource;
}

export enum EventCssClass {
  agent = 'agent',
  completed = 'completed',
  danger = 'danger',
  info = 'info',
  lead = 'lead',
  new = 'new',
  rehash = 'rehash',
  success = 'success',
  warning = 'warning',
  stop = 'stop',
  likelyStop = 'likelyStop'
}

export enum EventState {
  completed = 'completed',
  delivered = '200 OK',
  pending = 'pending',
  read = 'read',
  unread = 'unread',
  failed = 'failed'
  // NOTE: Ask Russ what other states he is using
}

export enum EventType {
  completedEvent = 'completedEvent',
  deferredEvent = 'deferredEvent',
  textEvent = 'textEvent',
  mmsEvent = 'mmsEvent',
  rehashEvent = 'rehashEvent',
  transferEvent = 'transferEvent',
  questionResponseEvent = 'questionResponseEvent',
  optOutEvent = 'optOutEvent',
  reopenEvent = 'reopenEvent',
  linkEvent = 'linkEvent',
  aiEvent = 'aiEvent'
}

export enum EventSource {
  ai = 'AMPAI'
}

export interface CompletedEvent extends Event {
  messageType: EventType.completedEvent;
}

export interface ReopenEvent extends Event {
  messageType: EventType.reopenEvent;
}

export interface DeferredEvent extends Event {
  messageType: EventType.deferredEvent;
  thenStartNew: boolean;
}

export interface TextEvent extends Event {
  messageType: EventType.textEvent;
  thenStartNew: boolean;
}

export interface MMSEvent extends Event {
  messageType: EventType.mmsEvent;
  media?: string;
  media_id?: string;
  preview_url?: string;
  thumbnail_url?: string;
  media_type?: string;
  title?: string;
  thenStartNew: boolean;
}

export interface RehashEvent extends Event {
  messageType: EventType.rehashEvent;
}

export interface TransferEvent extends Event {
  toAgent: Agent;
  messageType: EventType.transferEvent;
}

export interface QuestionResponseEvent extends Event {
  questionId: string;
  questionTitle: string;
  questionResponse: string;
  readonly messageType: EventType.questionResponseEvent;
}

export interface OptOutEvent extends Event {
  readonly cssClass: EventCssClass.info;
  readonly messageType: EventType.optOutEvent;
  readonly state: EventState.pending;
  phone: string;
  conversation_id;
}

export interface AiEvent extends Event {
  readonly cssClass: EventCssClass.info;
  readonly messageType: EventType.aiEvent;
  tags?: string[];
  scores?: AiScores;
  explanation?: string;
}
